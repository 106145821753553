<script setup>
const slug = computed(() => {
	let { slug } = useRoute().params

	if (!slug) {
		return 'home'
	}

	if (slug[slug.length - 1] === '') {
		slug.pop()
	}

	slug = slug.length > 1 ? slug.join('/') : slug[0]

	return slug.replace(/\/$/, '')
})

const { storyblokVersion: version } = useRuntimeConfig().public
const resolveRelations = 'Home.tiles,Overview.tiles'

const story = await useAsyncStoryblok(
	slug.value,
	{
		version,
		language: useI18n().locale.value,
		resolve_links: 'url',
		resolve_relations: resolveRelations
	},
	{
		resolveRelations,
		resolveLinks: 'url',
		preventClick: true
	}
)

if (story.value.status) {
	throw createError({
		statusCode: story.value.status,
		message: story.value.response
	})
}

const i18nHead = useLocaleHead({ addSeoAttributes: true })

useHead({
	htmlAttrs: { lang: i18nHead.value.htmlAttrs.lang },
	link: [...i18nHead.value.link],
	meta: [...i18nHead.value.meta]
})

const { seoSettings } = story.value?.content ?? {}
const {
	pageTitle: seoTitle,
	description: seoDescription,
	image: seoImage
} = seoSettings?.[0] || {}

const title = () => {
	return seoTitle || story.value?.content?.title
}

const description = () => {
	return seoDescription || story.value?.content?.description
}

useSeoMeta({
	ogImage: () => seoImage?.filename,
	twitterImage: () => seoImage?.filename,
	title,
	ogTitle: title,
	twitterTitle: title,
	description,
	ogDescription: description,
	twitterDescription: description
})
</script>

<template>
	<StoryblokComponent
		v-if="story"
		:blok="story.content"
		:date="story?.sort_by_date"
		class="grid"
	/>
</template>

<style scoped>
main {
	> section {
		position: relative;
		max-width: 100vw;

		& ul,
		& ol,
		& p {
			max-width: var(--content-max-width);
		}

		& ul,
		& ol {
			padding-inline-start: var(--space);
			line-height: 1.75;
		}

		& p {
			max-width: var(--content-max-width);
			line-height: 1.5;
		}
	}

	> h1:first-child {
		margin-block-start: var(--space);
	}
}
</style>
